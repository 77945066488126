import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—March 2009" />
	<NewsHeader />
    <h2>March 2009</h2>


			<h3 className="blogdate">
			Thursday, March 19, 2009
			</h3>

			<h4 className="blogitemtitle">
			New website
			</h4>
				<div className="blogitembody">
					<p>Well, really it's just a revamp of the old website, which was too limited, fragmented and inconsistent.  My new site has a unified theme throughout all pages, and most importantly it has all the navigational links on the left, and all the cool widgets on the right... on every page.</p>
					<p>
					There's a few cool goodies added.  In the right widget pane there's a search tool for finding content within jesserivest.com, plus a Twitter widget for real-time updates from me - Twitter is pretty exciting stuff!  Oh, and if you're on the news page or a blog page, then you'll also see Google Adsense appearing in the right widget pane... yup, I'm into making a bit of extra cash.  In the left navigation pane, I've added photos and videos links, and the Subscribe section is where you can stay up to date with a variety of RSS feeds.
					</p><p>
					Okay, enough of working on websites (hopefully) and back to music.  I'm scheduled to shoot a video tomorrow for Do It Now, and I'm currently investigating friends' studios for recording a new CD.  And then there's the tour timeline for the next year to be worked on!
					</p><p>
					Ciao for now,
					</p><p>
					Jesse
					</p>
				</div>
				<div className="blogitemfooter">
				<p>posted by Jesse @ 09:59 +1300</p>
				</div>




	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
